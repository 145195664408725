.font{
  font-family: 'Plus Jakarta Sans';
}
select option {
  margin: 40px;
  background-color: transparent !important;

  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.container {
  width: 100%;
  display: flex;
  align-self: center;
  margin: auto;
}

.backimg {
  position: 0 -300px;
  filter: brightness(50%);
  width: -webkit-fill-available;
  height: 300px;
}

.image_title {
  font-weight: 700;
  font-size: 40px;
}

.image_content {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.76);
}

.App-logo {
  height: 2rem;
  pointer-events: none;
}


h1 {
  color: white;
}

a {
  text-decoration: none !important;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 10px 0px;
  background-color: white;
}
.ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
  padding-bottom: 10px;
  background-color: white;
}
.ant-collapse > .ant-collapse-item-active > .ant-collapse-content-active > .ant-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 0px;
}

.checkout .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: transparent;
}
.pinpoint-modal.ant-modal .ant-modal-content {
  border-radius: 16px;
}

.pinpoint-modal.ant-modal .ant-modal-content .ant-modal-header{
  border-radius: 16px;
}

.header-p-0.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}
div#custom-phone .PhoneInput {
  width: 100%;
}
div#custom-phone .PhoneInputInput {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263F;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #D2DDEC;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div#custom-phone .PhoneInput--focus .PhoneInputInput {
  color: #12263F;
  background-color: #FFFFFF;
  border-color: #2C7BE5;
  outline: 0;
  box-shadow: none;
}

div#custom-phone.border-danger .PhoneInputInput {
  border-color: red;
}
.border-danger{
  border-color: red !important;
  border: 1px solid;
}

.dateError  > .ant-select-selector {
  border-color: red !important;
}
.gm-ui-hover-effect{
    display: none!important;
}

.gm-style .custom-tile::after {
  background: #fff !important;
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}
.gm-style .gm-style-iw-tc::after {
  background: none;
  box-shadow: none;
}

.h-62 {
  height: 62px;
}
.h-65 {
  height: 65px;
}

.h-80 {
  height: 80px;
}

.h-210 {
  height: 210px;
}

.h-200 {
  height: 200px;
}
.py-1 {
	padding-top: 1px;
	padding-bottom: 1px;
}
.py-11 {
	padding-top: 11px;
	padding-bottom: 11px;
}

.discount-form {
  overflow: hidden;
}

@keyframes slide-down {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 200px; /* Adjust as needed */
    opacity: 1;
  }
}
